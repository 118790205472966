import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rolling-stock"
    }}>{`Rolling-Stock`}</h1>
    <p>{`Rolling stock is a database that contains rolling material. This may be a train carriage or a bus.
It may also be a airplane or a ferry, so "rolling" is somewhat misleading.`}</p>
    <p>{`For simplicities sake, trains are used as a reference to easier understand the concepts.`}</p>
    <p><img alt="ER Model" src={require("./ER-model.png")} /></p>
    <h2 {...{
      "id": "basic-element"
    }}>{`Basic Element`}</h2>
    <p>{`A basic element describe a physical carriage. For example the adaption of litra A7-1 by VY.`}</p>
    <h2 {...{
      "id": "logical-element"
    }}>{`Logical Element`}</h2>
    <p>{`A logical element describes a carriage as it would be used in a given application.
For example th operator's own version of litra A7-1 as it is being used day trains on the Dovre line.
The accompanying basic element is identified by id or the series/version.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`You need a valid token to use this service. In order to issue a valid token you must have a client_id and
client_secret(the mechanisms for obtaining these are not in place yet).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      